<template>
   <div class="survey mobile">
       <div class="scroll">
           <div class="survey-top">
                <img :src="staticUrl+'design/shoujiduan/pre.jpg'">
            </div>
            <div class="survey-middle">
                <div class="survey-success">
                    <div>
                        <img :src="staticUrl+'design/shoujiduan/queren.png'">
                    </div>
                    <h3>提交成功</h3>
                    <p>感谢您的反馈</p>
                    <div>企业名称：{{comp.name}}</div>
                </div>
            </div>
            <div class="survey-middle2">
                <div>
                    <img :src="staticUrl+'design/shoujiduan/choujiang.png'">
                </div>
                <el-button @click="goLottery()" style="width:150px;margin:30px 0;" type="primary" round>点我去抽奖</el-button>
            </div>
            <!-- <div class="survey-button flex flex-align-center flex-pack-center">
                <el-button @click="goto('/center')" size="small" type="primary">进入产业智联网工作台</el-button>
            </div> -->
       </div>
   </div>
</template>
<script>
    import { STATIC_URL_PRE } from '@/config';
    import { shopDetailId } from '@/service/mobile';
    import { getCompBaseInfoById } from '@/service/company';
    export default {
        components: {},
        data() {
            return {
                // imgUrl:require('../../assets/images/bg1.jpg'),
                staticUrl:STATIC_URL_PRE,
                comp:null,
            }
        },
        async created() {
            this.comp = this.$store.state.loginUser.comp;
            document.title = "金融支持下的备料服务调研";
        },
        mounted() {
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', function () {
                history.pushState(null, null, document.URL);
            });
        },
        destroyed() {
            // 当页面销毁必须要移除这个事件，vue不刷新页面，不移除会重复执行这个事件
            window.removeEventListener("popstate", this.onBrowserBack, false);
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            goto(path){
                this.$router.push(path);
            },
            goLottery() {
                window.open("https://hd.faisco.cn/23768895/S3ckb31zbSXdflHaHg7Npw/load.html?style=75&_source=1")
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped lang="less">
.survey-top {
    width: 94%;
    margin: 10px auto 10px;
    img {
        width: 100%;
    }
}
.survey-middle {
    width: 100%;
    text-align: center;
    .survey-success {
        margin-top: 30px;
        >div>img {
            width: 110px;
            padding-left: 30px;
        }
        h3 {
            font-size:18px;
            font-weight:bold;
            color:rgba(77,77,77,1);
            line-height:35px;
            margin-bottom: 0;
        }
        p {
            font-size:16px;
            color:#808080;
            line-height:30px;
            margin-bottom: 10px;
        }
        >div {
            font-size: 16px;
        }
    }
}
.survey-middle2 {
    width: 80%;
    margin: 30px auto 50px;
    padding: 14px 0;
    text-align: center;
    background:rgba(252,253,255,1);
    box-shadow:0px 2px 4px 0px rgba(219,225,246,1);
    border-radius:10px;
    div {
        font-size: 16px;
        img {
            width: 170px;
        }
    }
}
.survey-button {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    box-shadow:0px -1px 0px 0px rgba(230,230,230,1);
    position: absolute;
    left:0;
    bottom: 0;
}
</style>